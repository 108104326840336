import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery, Typography,  IconButton } from '@material-ui/core';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';
import PinterestIcon from '@material-ui/icons/Pinterest';


const useStyles = makeStyles(theme => ({
  section: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(4),
    },
  },
  image: {
    objectFit: 'cover',
    borderRadius: theme.spacing(1),
  },
  socialIcon: {
    borderRadius: 0,
    marginRight: theme.spacing(2),
    color: theme.palette.text.primary,
    background: theme.palette.alternate.main,
    '&:last-child': {
      marginRight: 0,
    },
  },
}));

const Content = props => {
  const { data, className, ...rest } = props;
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <div className={className} {...rest}>
       <div className={classes.section}>
        <Typography component="p" variant="body1" color="primary" align="center">
          "{data.quote}"
        </Typography>
      </div>
      <div className={classes.section}>
        <Typography component="p" variant="body1" color="primary" align="center">
          "{data.quote1}"
        </Typography>
      </div>
      <div className={classes.section}>
        <Typography component="p" variant="body1" color="primary" align="center">
          "{data.quote2}"
        </Typography>
      </div>
      <div className={classes.section}>
        <Typography component="p" variant="body1" color="primary" align="center">
          "{data.quote3}"
        </Typography>
      </div>
      <div className={classes.section}>
        <Typography component="p" variant="body1" color="primary" align="center">
          "{data.quote4}"
        </Typography>
      </div>
      <div className={classes.section}>
        <Typography component="p" variant="body1" color="primary" align="center">
          "{data.quote5}"
        </Typography>
      </div>
      <div className={classes.section}>
        <Typography component="p" variant="h4" color="textPrimary">
          {data.question}
        </Typography>
      </div>
      <div className={classes.section}>
        <Typography component="p" variant="h6" color="textPrimary">
          {data.headline}
        </Typography>
      </div>
      <div className={classes.section}>
        <Typography component="p" variant="h4" color="textPrimary">
          {data.question1}
        </Typography>
      </div>

      <h3>FACTS: They say Numbers don’t lie!</h3>
      <br/>
      <ul>
      
      	<li>Although rare diseases affect a handful of people in specific countries but If we take in account the total number around the globe, they may sum up almost equivalent to the population of World’s third largest country.(1) AND WE DEFINITELY WANT IT TO BE HAPPY.</li>
        <br/>
	<li>It takes an average of seven heart breaking years involving 8 physicians visits, 2-3 misdiagnosis to finally arrive at an accurate diagnosis of a rare disease., with chances of misdiagnosis as well. End of diagnostic odyssey is what beautiful future looks like.</li>
  <br/>
<li>During a survey at medical fraternity in India, about three-fourth out of 193 respondents were found to have no awareness regarding rare diseases. Wake up INDIA!! </li>
  <br/>
<li>Around 3.5% - 5.9% of the worldwide population are currently affected by rare diseases. Within which nearly 60% of the rare diseases affect children, 30% of them happen to die before 5 years of age. Approximately 80% of the rare disorders have an underlying genetic origin.  Its all in the genes.</li>


</ul>

<br/>
     
      <div className={classes.section}>
        <Typography component="p" variant="h6" color="textPrimary">
          {data.text1}
        </Typography>
      </div>
      {/* <div className={classes.section}>
        <GridList
          cellHeight={isMd ? 360 : 260}
          cols={2}
          spacing={isMd ? 24 : 8}
        >
          {data.images.map((item, index) => (
            <GridListTile key={index} cols={isMd ? item.cols : 2}>
              <Image
                {...item}
                className={classes.image}
                lazyProps={{ width: '100%', height: '100%' }}
              />
            </GridListTile>
          ))}
        </GridList>
      </div> */}
      <div className={classes.section}>
        <Typography component="p" variant="h6" color="textPrimary">
          {data.text2}
        </Typography>
      </div>
      <div className={classes.section}>
        <Typography component="p" variant="h6" color="textPrimary">
          {data.text3}
        </Typography>
      </div>

     <h4> References</h4>
     <ol>
<li>Ng SB, Buckingham KJ, Lee C et al, Exome sequencing identifies the cause of a Mendelian disorder . Nat Genet 2010 ; 42 : 30 – 35 .</li>
<li>https://www.rarediseaseday.org/article/what-is-rare-disease-day</li>
<li> https://main.mohfw.gov.in/newshighlights/national-policy-rare-diseases-2020</li>
<li>https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6318767/#MCS003392FREC37</li>
</ol>

<br/>

      <div>
        <IconButton className={classes.socialIcon}>
          <FacebookIcon />
        </IconButton>
        <IconButton className={classes.socialIcon}>
          <InstagramIcon />
        </IconButton>
        <IconButton className={classes.socialIcon}>
          <TwitterIcon />
        </IconButton>
        <IconButton className={classes.socialIcon}>
          <PinterestIcon />
        </IconButton>
      </div>
    </div>
  );
};

Content.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * data to be rendered
   */
  data: PropTypes.object.isRequired,
};

export default Content;
