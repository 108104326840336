export const sidebarArticles = [
  {
    cover: {
      src: 'https://assets.maccarianagency.com/the-front/photos/blog/cover4.jpg',
      srcSet: 'https://assets.maccarianagency.com/the-front/photos/blog/cover4.jpg 2x',
    },
    title: "1 Yes equals to 8 lives",
    author: {
      photo: {
        src: 'https://assets.maccarianagency.com/the-front/photos/people/kate-segelson.jpg',
        srcSet: 'https://assets.maccarianagency.com/the-front/photos/people/kate-segelson@2x.jpg 2x',
      },
      name: 'Kate Segelson',
    },
    date: '04 Aug',
    tags: ['larq', 'bottle', 'shop', 'drinks', 'eco', 'self washing'],
  },
  {
    cover: {
      src: 'https://assets.maccarianagency.com/the-front/photos/blog/cover3.jpg',
      srcSet: 'https://assets.maccarianagency.com/the-front/photos/blog/cover3.jpg 2x',
    },
    title: 'So what is stopping you now to opt for an NIPT test in your labs ?',
    author: {
      photo: {
        src: 'https://assets.maccarianagency.com/the-front/photos/people/jack-smith.jpg',
        srcSet: 'https://assets.maccarianagency.com/the-front/photos/people/jack-smith@2x.jpg 2x',
      },
      name: 'Jack Smith',
    },
    date: '04 Aug',
    tags: ['nike', 'sport', 'shop', 'training'],
  },
  {
    cover: {
      src: 'https://assets.maccarianagency.com/the-front/photos/blog/cover2.jpg',
      srcSet: 'https://assets.maccarianagency.com/the-front/photos/blog/cover2.jpg 2x',
    },
    title: 'Genomicist: A Superhero',
    author: {
      photo: {
        src: 'https://assets.maccarianagency.com/the-front/photos/people/akachi-luccini.jpg',
        srcSet: 'https://assets.maccarianagency.com/the-front/photos/people/akachi-luccini@2x.jpg 2x',
      },
      name: 'Akachi Luccini',
    },
    date: '04 Aug',
    tags: ['adidas', 'sport', 'shop', 'training'],
  },
  {
    cover: {
      src: 'https://assets.maccarianagency.com/the-front/photos/blog/cover1.jpg',
      srcSet: 'https://assets.maccarianagency.com/the-front/photos/blog/cover1.jpg 2x',
    },
    title: 'Who is Special!',
    author: {
      photo: {
        src: 'https://assets.maccarianagency.com/the-front/photos/people/veronica-adams.jpg',
        srcSet: 'https://assets.maccarianagency.com/the-front/photos/people/veronica-adams@2x.jpg 2x',
      },
      name: 'Veronica Adams',
    },
    date: '04 Aug',
    tags: ['coffee', 'cups', 'morning coffee', 'breakfast'],
  },
  {
    cover: {
      src: 'https://assets.maccarianagency.com/the-front/photos/blog/cover1.jpg',
      srcSet: 'https://assets.maccarianagency.com/the-front/photos/blog/cover1.jpg 2x',
    },
    title: 'Illumina – Powering the era of NGS',
    author: {
      photo: {
        src: 'https://assets.maccarianagency.com/the-front/photos/people/veronica-adams.jpg',
        srcSet: 'https://assets.maccarianagency.com/the-front/photos/people/veronica-adams@2x.jpg 2x',
      },
      name: 'Veronica Adams',
    },
    date: '04 Aug',
    tags: ['coffee', 'cups', 'morning coffee', 'breakfast'],
  },
  {
    cover: {
      src: 'https://assets.maccarianagency.com/the-front/photos/blog/cover1.jpg',
      srcSet: 'https://assets.maccarianagency.com/the-front/photos/blog/cover1.jpg 2x',
    },
    title: 'Who is Special!',
    author: {
      photo: {
        src: 'https://assets.maccarianagency.com/the-front/photos/people/veronica-adams.jpg',
        srcSet: 'https://assets.maccarianagency.com/the-front/photos/people/veronica-adams@2x.jpg 2x',
      },
      name: 'Veronica Adams',
    },
    date: '04 Aug',
    tags: ['coffee', 'cups', 'morning coffee', 'breakfast'],
  },
];

export const similarStories = [
  {
    cover: {
      src: 'https://assets.maccarianagency.com/the-front/photos/blog/cover2.jpg',
      srcSet: 'https://assets.maccarianagency.com/the-front/photos/blog/cover2.jpg 2x',
    },
    title: 'Adidas will release your favourite shoes',
    subtitle:
      'Get your favourite adidas shoes, clothing & accessories at the official website! adidas Training.',
    author: {
      photo: {
        src: 'https://assets.maccarianagency.com/the-front/photos/people/akachi-luccini.jpg',
        srcSet: 'https://assets.maccarianagency.com/the-front/photos/people/akachi-luccini@2x.jpg 2x',
      },
      name: 'Akachi Luccini',
    },
    date: '04 Aug',
    tags: ['adidas', 'sport', 'shop', 'training'],
  },
  {
    cover: {
      src: 'https://assets.maccarianagency.com/the-front/photos/blog/cover3.jpg',
      srcSet: 'https://assets.maccarianagency.com/the-front/photos/blog/cover3.jpg 2x',
    },
    title: 'NIKE Online Store launches the website‎',
    subtitle:
      'Be Your Best Every Time With Nike Shoes And Clothing. Shop Online. The Official Website. Home Of Everything Nike. Shop The Latest Releases Today! Types: Shoes, Tops.',
    author: {
      photo: {
        src: 'https://assets.maccarianagency.com/the-front/photos/people/jack-smith.jpg',
        srcSet: 'https://assets.maccarianagency.com/the-front/photos/people/jack-smith@2x.jpg 2x',
      },
      name: 'Jack Smith',
    },
    date: '04 Aug',
    tags: ['nike', 'sport', 'shop', 'training'],
  },
  {
    cover: {
      src: 'https://assets.maccarianagency.com/the-front/photos/blog/cover4.jpg',
      srcSet: 'https://assets.maccarianagency.com/the-front/photos/blog/cover4.jpg 2x',
    },
    title: "LARQ | World's First Self-cleaning Water Bottle‎",
    subtitle:
      "A self-cleaning water bottle that'll help you reach your hydration goal. Neutralizes up to 99%* of harmful, odor-causing bacteria using UV-C light.",
    author: {
      photo: {
        src: 'https://assets.maccarianagency.com/the-front/photos/people/kate-segelson.jpg',
        srcSet: 'https://assets.maccarianagency.com/the-front/photos/people/kate-segelson@2x.jpg 2x',
      },
      name: 'Kate Segelson',
    },
    date: '04 Aug',
    tags: ['larq', 'bottle', 'shop', 'drinks', 'eco', 'self washing'],
  },
];

export const content = {

  quote:
  ' I like shouting at sky when it rains.',
  quote1:
  'Nobody accepts me like I am',
  quote2:
  'For a long time, people didn’t believe that I was really sick, because they couldn’t see my illness',
  quote3:
  'Why do I have to go to school? I get tired, I get pain',
  quote4:
  'People say its all in your head',
  quote5:
  'These heart wrenching lines are said by people like us, having rare diseases',
  
  question:'How much Unseen are the Rare Diseases?',
  headline:
    'Rare diseases are stated rare because of less established data for each individual rare disease. Unlike traditional methods of diagnosis which employed evaluation by a characteristic pattern or signs or symptoms, such diagnosis are not feasible for these cases. They are rare but not invisible. RARE IS THERE!',
    question1:'What prompted me the most to write this article and spread awareness about the existence of rare diseases?',
    
    content1: 'Despite being “no or negligible chances of cure” for their children’s condition, their family feels empowered with the diagnosis means. Timely diagnosis by rational genetic tests, chosen judiciously can help these families attain a normal life. Apt and accurate diagnosis is the first step towards understanding ins and outs of a disease, exploring ways to attenuate the complications, providing supportive treatment and curative therapy options. NEED OF THE HOUR Rational genetic tests like Next-generation sequencing (NGS) have revolutionized medical genetics by improving the chances of obtaining a molecular diagnosis for rare genetic diseases. Whole-exome sequencing (WES) has shown an unprecedented success rate in the identification of disease-causing genes.(2) And also has ushered in a diversity of approaches to have a clarity of genome structure and its function. In case of rare genetic diseases, these approaches have greatly accelerated gene discovery and patient diagnosis. Over the past 10 years, whole exome sequencing has surfaced as a comprehensive and cost-efficient approach to identify variants in the protein-coding (exomes) regions of the genome.(4) NGS-based genetic testing in the diagnosis of rare diseases holds incredible promise in upcoming years. Advancement of NGS technologies gives numerous options for diagnosing rare disorders associated with different types of genetic variants. An error free diagnosis for rare disease which earlier took years is now possible in 5-8 weeks via Next Generation Sequencing.* GREAT SUCCESS INDEED! Undoubtedly, there are plenty of challenges in course of action due to insufficient knowledge about Rare disease which are rather global than considered. We need to spread awareness in society and bring up a generation more sensible towards rare disease cues and needs along with aiming for better government strategies and envision more cures and care for them ',
    refrences:'https://www.rarediseaseday.org/article/what-is-rare-disease-day',


  
  
  text1:
    ' Despite being “no or negligible chances of cure” for their children’s condition, their family feels empowered with the diagnosis means. Timely diagnosis by rational genetic tests, chosen judiciously can help these families attain a normal life. Apt and accurate diagnosis is the first step towards understanding ins and outs of a disease, exploring ways to attenuate the complications, providing supportive treatment and curative therapy options. NEED OF THE HOUR',
  text2:
    'Rational genetic tests like Next-generation sequencing (NGS) have revolutionized medical genetics by improving the chances of obtaining a molecular diagnosis for rare genetic diseases. Whole-exome sequencing (WES) has shown an unprecedented success rate in the identification of disease-causing genes.(2) And also has ushered in a diversity of approaches to have a clarity of genome structure and its function. In case of rare genetic diseases, these approaches have greatly accelerated gene discovery and patient diagnosis. Over the past 10 years, whole exome sequencing has surfaced as a comprehensive and cost-efficient approach to identify variants in the protein-coding (exomes) regions of the genome.(4) NGS-based genetic testing in the diagnosis of rare diseases holds incredible promise in upcoming years. Advancement of NGS technologies gives numerous options for diagnosing rare disorders associated with different types of genetic variants. An error free diagnosis for rare disease which earlier took years is now possible in 5-8 weeks via Next Generation Sequencing.* GREAT SUCCESS INDEED!',
 
 text3:'Undoubtedly, there are plenty of challenges in course of action due to insufficient knowledge about Rare disease which are rather global than considered. We need to spread awareness in society and bring up a generation more sensible towards rare disease cues and needs along with aiming for better government strategies and envision more cures and care for them.',
 
    title: 'Rare diseases: Rare or Invisible? ',
  subtitle:
    'We need to spread awareness in society and bring up a generation more sensible towards rare disease cues and needs along with aiming for better government strategies and envision more cures and care for them.',
  author: {
    photo: {
      src: 'https://ik.imagekit.io/j6phbjneuop/bhamini_pdVrKvAw4.png',
      srcSet: 'https://ik.imagekit.io/j6phbjneuop/bhamini_pdVrKvAw4.png',
    },
    name: 'Bhamini Arora :Product Executive (Clinical), Premas Life Sciences ',
    date: 'May 20, 2019',
  },
  cover: {
    src: 'https://ik.imagekit.io/j6phbjneuop/ezgif.com-gif-maker__7__5bmR5y1KA.gif?updatedAt=1625473274689',
    srcSet: 'https://ik.imagekit.io/j6phbjneuop/ezgif.com-gif-maker__7__5bmR5y1KA.gif?updatedAt=1625473274689',
  },
  images: [
    {
      src: 'https://assets.maccarianagency.com/the-front/photos/blog/cover4.jpg',
      srcSet: 'https://assets.maccarianagency.com/the-front/photos/blog/cover4.jpg 2x',
      cols: 2,
    },
    {
      src: 'https://assets.maccarianagency.com/the-front/photos/blog/cover3.jpg',
      srcSet: 'https://assets.maccarianagency.com/the-front/photos/blog/cover3.jpg 2x',
      cols: 1,
    },
    {
      src: 'https://assets.maccarianagency.com/the-front/photos/blog/cover1.jpg',
      srcSet: 'https://assets.maccarianagency.com/the-front/photos/blog/cover1.jpg 2x',
      cols: 1,
    },
  ],
};
